window.initBtnWarranty = () => {
  const list = document.querySelectorAll('[data-btn-warranty]');
  if (list) {
    const modalWindowWarranty = async () => {
      let body;
      try {
        const response = await fetch('/index.php?route=api/warranty');
        if (response.ok) {
          body = document.createElement('div');
          body.innerHTML = await response.text();
        } else {
          throw new Error(`${response.status} ${response.statusText}`);
        }
      } catch (err) {
        body = document.createTextNode(`Помилка відправлення: ${err.message}`);
      }

      new window.ModalWindow('Гарантия', body);
    };

    // ============================================================

    list.forEach(el => {
      delete el.dataset.btnDelivery;
      el.addEventListener('click', modalWindowWarranty);
    });
  }
};

window.initBtnWarranty();
