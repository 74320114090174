window.shared = {};

window.shared.throttle = (callback, wait, immediate = false) => {
  let timeout = null;
  let initialCall = true;

  return (...args) => {
    const callNow = immediate && initialCall;

    const run = () => {
      callback(...args);
      timeout = null;
    };

    if (callNow) {
      initialCall = false;
      run();
    }

    if (!timeout) timeout = setTimeout(run, wait);
  };
};

window.shared.debounce = (callback, wait, immediate = false) => {
  let timeout = null;

  return (...args) => {
    const callNow = immediate && !timeout;
    const run = () => callback(...args);

    clearTimeout(timeout);
    timeout = setTimeout(run, wait);

    if (callNow) run();
  };
};

window.shared.escapeRegExp = str => str.replace(/[\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');

window.shared.checkEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

// ================================================

$(document).on('click', '.product__options-link', function () {
  $(this).children('svg').toggleClass('active');
  $(this).siblings('.product__characteristics-wrapper').animate(
    {
      height: 'toggle'
    }, 200
  );
});

// ================================================

// pages: income, catalog
(() => {
  const layoutBoxCatalog = document.querySelector('.layout-box__catalog');
  const viewButtons = document.querySelectorAll('.sort-buttons button');

  if (viewButtons.length) {
    const setViewProducts = target => {
      viewButtons.forEach(el => el.classList.remove('active'));
      target.classList.add('active');

      if (target.classList.contains('line')) {
        layoutBoxCatalog.classList.add('catalog-line');
        localStorage.removeItem('viewGrid');
      } else {
        layoutBoxCatalog.classList.remove('catalog-line');
        localStorage.setItem('viewGrid', true);
      }
    };

    viewButtons.forEach(el => el.addEventListener('click', ({ target }) => setViewProducts(target)));

    if (localStorage.getItem('viewGrid')) {
      setViewProducts(document.querySelector('.sort-buttons button:last-child'));
    }
  }

  // ===============================

  const wrapperBtnLoadMore = document.querySelector('.wrapper-btn-load-more');
  const wrapperPagination = document.querySelector('.wrapper-pagination');

  if (wrapperBtnLoadMore) {
    const onClickIncomeWrapperBtnLoadMore = async evt => {
      const el = evt.target;
      if (el.tagName !== 'BUTTON') return;

      const dataset = { ...el.dataset };
      const { route } = dataset;
      delete dataset.route;

      const body = JSON.stringify(dataset);

      try {
        const response = await fetch(`/index.php?route=${route}/products`, { method: 'POST', body });
        if (response.ok) {
          const responseData = await response.json();
          layoutBoxCatalog.insertAdjacentHTML('beforeend', responseData.list);
          wrapperPagination.innerHTML = responseData.pagination;
          wrapperBtnLoadMore.innerHTML = responseData.btnLoadMore;

          window.initBtnDelivery();
          window.initBtnWarranty();
          window.initBtnFavorite();
          window.initBtnBuy();
          window.initBtnCart();
          window.initBtnWishlist();
          window.initBtnLogin();
          return;
        }
        throw new Error(`${response.status} ${response.statusText}`);
      } catch (err) {
        console.error(err);
      }
    };

    wrapperBtnLoadMore.addEventListener('click', onClickIncomeWrapperBtnLoadMore);
  }
})();

// ===============================

if (typeof Swiper !== 'undefined') {
  const list = document.querySelectorAll('[data-product-slider]');
  if (list) {
    const options = {
      slidesPerView: 2,
      spaceBetween: 8,
      breakpoints: {
        601: {
          slidesPerView: 3,
          spaceBetween: 16
        },
        854: {
          slidesPerView: 4,
          spaceBetween: 16
        },
        1441: {
          slidesPerView: 5,
          spaceBetween: 16
        }
      },
      navigation: {
        nextEl: '.product-swiper__button-nav--next',
        prevEl: '.product-swiper__button-nav--prev'
      },
      scrollbar: {
        el: '.product-swiper__scrollbar',
        dragClass: 'product-swiper__scrollbar-drag',
        draggable: true
      }
    };
    list.forEach(el => new Swiper(el, options));
  }
}

// ===============================

const preloader = document.getElementById('preloader');

const preloaderChange = () => {
  preloader.classList.toggle('preloader--open');
  document.body.classList.toggle('body--preloader-open');
};

window.preloaderOpen = preloaderChange;
window.preloaderHide = preloaderChange;

// ========================================

const onClickDownloadPriceList = async event => {
  event.preventDefault();
  window.preloaderOpen();
  const response = await fetch(event.target.href);
  const blob = await response.blob();

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = response.headers.get('content-disposition')
    .match(/filename="(.*)"/)[1];
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.preloaderHide();
};

const downloadPriceListLinks = document.querySelectorAll('[data-btn-price-list]');
downloadPriceListLinks.forEach(el => el.addEventListener('click', onClickDownloadPriceList));
