const setHeaderCartCounter = count => {
  const headerCartCounter = document.getElementById('headerCartCounter');
  if (!headerCartCounter) return;
  if (count) {
    headerCartCounter.textContent = count;
    headerCartCounter.classList.remove('btn-header-action__counter--hidden');
  } else {
    headerCartCounter.classList.add('btn-header-action__counter--hidden');
  }
};

// ===================================================================

const initCart = () => {
  const cart = document.getElementById('cart');
  if (!cart) return;
  const onCartClose = () => document.body.classList.remove('body--cart-open');
  cart.addEventListener('click', evt => evt.target === evt.currentTarget && onCartClose());

  const cartClose = cart.querySelector('.cart__btn-close');
  cartClose.addEventListener('click', onCartClose);

  // ===================================================================

  const cartContainer = cart.querySelector('.cart__container');
  const cartWrapper = cart.querySelector('.cart__wrapper');

  const setCartContainerHeight = height => cartContainer.style.setProperty('--height', height);
  const onResizeCart = () => setCartContainerHeight(`${cartWrapper.scrollHeight}px`);
  const onResizeCartThrottle = window.shared.throttle(onResizeCart, 500);

  window.cartOpen = () => {
    document.body.classList.toggle('body--cart-open');
    if (document.body.classList.contains('body--cart-open')) {
      onResizeCartThrottle();
      window.addEventListener('resize', onResizeCartThrottle);
    } else {
      window.removeEventListener('resize', onResizeCartThrottle);
    }
  };

  // ===================================================================

  const cartClear = cart.querySelector('.cart__clear');
  if (cartClear) {
    const onClickCartClear = async evt => {
      evt.target.disabled = true;

      const url = '/index.php?route=shared/cart/clear';
      try {
        const response = await fetch(url);
        if (!response.ok) throw new Error(`${response.status} ${response.statusText}`);
        window.cartGet();
      } catch (err) {
        console.error(err.message);
      }

      setHeaderCartCounter(0);
      onCartClose();
      evt.target.disabled = false;
    };

    cartClear.addEventListener('click', onClickCartClear);
  }

  // ============================================================================

  const cartTotalSumUAH = document.getElementById('cartTotalSumUAH');
  const cartTotalSumUSD = document.getElementById('cartTotalSumUSD');

  const onClickCartItemRemove = async evt => {
    evt.target.disabled = true;
    const item = evt.target.closest('.cart__item');
    const body = JSON.stringify({ id: item.dataset.id });
    const url = '/index.php?route=shared/cart/remove';

    try {
      const response = await fetch(url, { method: 'POST', body });
      if (response.ok) {
        const responseJSON = await response.json();
        setHeaderCartCounter(responseJSON.totalQuantity);
        if (!responseJSON.totalQuantity) {
          onCartClose();
          window.cartGet();
          return;
        }
        cartTotalSumUAH.textContent = responseJSON.totalUAH;
        cartTotalSumUSD.textContent = responseJSON.totalUSD;
        item.remove();
        return;
      }
      throw new Error(`${response.status} ${response.statusText}`);
    } catch (err) {
      console.error(err.message);
    }
  };

  const cartItemRemoveList = cart.querySelectorAll('.cart__item-remove');
  if (cartItemRemoveList) cartItemRemoveList.forEach(el => el.addEventListener('click', onClickCartItemRemove));

  // ============================================================================

  const cartChangeValue = async target => {
    const item = target.closest('.cart__item');
    const btnMinus = item.querySelector('.cart__item-quantity-btn--minus');
    const btnPlus = item.querySelector('.cart__item-quantity-btn--plus');
    const input = item.querySelector('.cart__item-quantity-input');

    let valueAdd = 0;
    if (target.classList.contains('cart__item-quantity-btn--minus')) valueAdd = -1;
    else if (target.classList.contains('cart__item-quantity-btn--plus')) valueAdd = 1;

    btnMinus.disabled = true;
    btnPlus.disabled = true;

    const quantity = +input.value + valueAdd;
    const response = await window.cartAdd(item.dataset.id, quantity);
    if (!response) return false;

    cartTotalSumUAH.textContent = response.totalUAH;
    cartTotalSumUSD.textContent = response.totalUSD;
    btnMinus.disabled = response.quantity === 1;
    btnPlus.disabled = response.isMaxQuantity;
    input.value = response.quantity;
    return true;
  };

  const cartItemQuantityBtnList = cart.querySelectorAll('.cart__item-quantity-btn');
  if (cartItemQuantityBtnList) {
    cartItemQuantityBtnList.forEach(el => el.addEventListener('click', ({ target }) => cartChangeValue(target)));
  }

  const cartItemQuantityInputList = cart.querySelectorAll('.cart__item-quantity-input');
  if (cartItemQuantityInputList) {
    const onChangeCartItemQuantityInput = async ({ target }) => {
      if (await cartChangeValue(target)) {
        target.dataset.value = target.value;
      } else {
        target.value = target.dataset.value;
      }
    };

    cartItemQuantityInputList.forEach(el => {
      el.addEventListener('focus', ({ target }) => target.dataset.value = target.value);
      el.addEventListener('input', onChangeCartItemQuantityInput);
    });
  }
};

initCart();

// ============================================================================

window.cartGet = async () => {
  const url = '/index.php?route=shared/cart/get';

  try {
    const response = await fetch(url);
    const responseText = await response.text();
    if (response.ok) {
      document.getElementById('cart').remove();
      document.body.insertAdjacentHTML('beforeend', responseText);
      initCart();
      return;
    }
    throw new Error(`${response.status} ${response.statusText}`);
  } catch (err) {
    console.error(err);
  }
};

// ============================================================================

window.cartAdd = async (id, quantity = 1, increment = false) => {
  const url = '/index.php?route=shared/cart/add';
  const body = JSON.stringify({ id, quantity, increment });

  let errorText;
  try {
    const response = await fetch(url, { method: 'POST', body });
    if (response.ok) {
      const responseJSON = await response.json();
      setHeaderCartCounter(responseJSON.totalQuantity);
      return responseJSON;
    }

    const responseText = await response.text();
    if (response.status === 400 && responseText === 'MAX_QUANTITY') {
      errorText = 'Недостатньо товарів на складі';
    } else {
      throw new Error(`${response.status} ${response.statusText}`);
    }
  } catch (err) {
    errorText = err.message;
  }

  new window.ModalWindow('Помилка', document.createTextNode(errorText));
  return false;
};
