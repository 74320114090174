window.initBtnDelivery = () => {
  const list = document.querySelectorAll('[data-btn-delivery]');
  if (list) {
    const modalWindowDelivery = async () => {
      let body;
      try {
        const response = await fetch('/index.php?route=api/delivery');
        if (response.ok) {
          body = document.createElement('div');
          body.innerHTML = await response.text();
        } else {
          throw new Error(`${response.status} ${response.statusText}`);
        }
      } catch (err) {
        body = document.createTextNode(`Помилка відправлення: ${err.message}`);
      }

      new window.ModalWindow('Условия оплаты и доставки', body);
    };

    // ============================================================

    list.forEach(el => {
      delete el.dataset.btnDelivery;
      el.addEventListener('click', modalWindowDelivery);
    });
  }
};

window.initBtnDelivery();
