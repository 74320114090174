window.initBtnBuy = () => {
  const list = document.querySelectorAll('[data-btn-buy]');
  if (list) {
    const modalWindowBuy = productId => {
      const form = window.ModalWindow.createFormElement();

      const phone = window.ModalWindow.createFormInputPhone({
        title: 'Телефон', name: 'phone', required: true, value: window.customerPhone
      });
      const formInputPhone = phone.element;
      form.appendChild(formInputPhone);

      const formBtn = window.ModalWindow.createFormBtn('Отправить');
      form.appendChild(formBtn);

      const onSubmit = async evt => {
        evt.preventDefault();
        formBtn.disabled = true;

        const url = '/index.php?route=api/buy';
        const body = JSON.stringify({
          phone: phone.mask.unmaskedValue,
          productId
        });

        let errorText;
        try {
          const response = await fetch(url, { method: 'POST', body });
          if (response.ok) {
            const responseText = 'Дякуємо за покупку! Ми зв\'яжемося з вами найближчим часом для оформлення замовлення.';
            const responseElement = window.ModalWindow.createResponse(responseText, 'success');
            evt.target.parentElement.appendChild(responseElement);
            evt.target.remove();
            return;
          }

          const responseText = await response.text();
          if (response.status === 400 && responseText === 'INVALID') {
            errorText = 'Помилка валідації';
          } else {
            throw new Error(`${response.status} ${response.statusText}`);
          }
        } catch (err) {
          errorText = `Помилка відправлення: ${err.message}`;
        }

        const modalWindowResponse = form.querySelector('.modal-window__response');
        if (modalWindowResponse) modalWindowResponse.remove();
        const responseElement = window.ModalWindow.createResponse(errorText, 'error');
        form.appendChild(responseElement);
        formBtn.disabled = false;
      };

      form.addEventListener('submit', onSubmit);
      new window.ModalWindow('Купить в 1 клик', form);
    };
    // ============================================================

    list.forEach(el => {
      delete el.dataset.btnBuy;
      el.addEventListener('click', ({ target }) => modalWindowBuy(target.closest('[data-product-id]').dataset.productId));
    });
  }
};

window.initBtnBuy();
